<template>
  <div
    class="notification-item border-b border-gray-400 last:border-none py-2 pr-4 cursor-pointer"
    :class="{
      'font-semibold': !notification?.read_at,
    }"
    @click.prevent="onMarkAsRead()"
  >
    <div class="notification-created text-xs">
      <span>{{ notification?.created_at }}</span>
      <span
        v-if="!notification?.read_at"
        class="notification_unread ml-2 w-2 h-2 rounded-full bg-[#04B800] inline-block"
      />
    </div>
    <div v-if="notification?.data?.content" class="notification-content mt-2 text-base">
      {{ notification?.data?.content }}
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  props: {
    notification: {
      type: Object,
      default: () => {},
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['notification-read'],
  methods: {
    onMarkAsRead() {
      if (this.notification?.read_at) {
        this.$inertia.visit(this.notification?.data?.url)
        return
      }
      axios
        .post('/notifications/mark-as-read', { id: this.notification?.id, admin: this.admin })
        .then(() => {
          if (this.notification?.data?.url) {
            this.$inertia.visit(this.notification?.data?.url)
            
          } else {
            this.$emit('notification-read', this.notification)
          }
        })
        .catch((error) => {
          this.$message({ message: error?.message, type: 'error' })
        })
    },
  },
}
</script>
