<template>
  <el-drawer v-model="drawer" :direction="direction" @close="handleClosedNotification">
    <template #header>
      <div class="font-semibold text-2xl text-[#202020]">{{ $t('Thông báo') }}</div>
    </template>
    <template #default>
      <NotificationList
        :unread="notifications_unread"
        :is-load-more="notifications.length >= filter.limit"
        @load-more="handleLoadMore"
      >
        <NotificationItem
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          :admin="admin"
          @notification-read="handleReadNotification"
        />
      </NotificationList>
    </template>
  </el-drawer>
</template>

<script>
import NotificationList from '@/Components/Notification/NotificationList.vue'
import NotificationItem from '@/Components/Notification/NotificationItem.vue'
import axios from '@/plugins/axios'
export default {
  components: { NotificationList, NotificationItem },
  props: {
    direction: {
      type: String,
      default: 'ltr',
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-unread'],
  data() {
    return {
      drawer: false,
      notifications: [],
      notifications_unread: 0,
      filter: {
        limit: 20,
      },
    }
  },
  methods: {
    toggle() {
      this.drawer = true
      this.fetchDataNotifications()
    },
    fetchDataNotifications() {
      if (this.admin) this.filter.admin = true
      axios.get('/notifications', { params: { ...this.filter } }).then(({ data }) => {
        this.notifications = data?.data?.notifications
        this.notifications_unread = data?.data?.notifications_unread
      })
    },
    handleReadNotification(notification) {
      this.notifications.find((item) => item.id === notification.id).read_at = new Date()
      this.notifications_unread = this.notifications_unread - 1
    },
    handleClosedNotification() {
      this.$emit('update-unread', this.notifications_unread)
    },
    handleLoadMore() {
      this.filter.limit = this.filter.limit + 20
      this.fetchDataNotifications()
    },
  },
}
</script>

<style>
.el-drawer__header {
  margin-bottom: 10px;
}
@media screen and (max-width: 1028px) {
  .el-drawer {
    width: 50% !important;
  }
}
@media screen and (max-width: 767px) {
  .el-drawer {
    width: 100% !important;
  }
}
</style>
